.tagsinput {
  height: auto !important;
  .control {
    margin-bottom: .1em !important;
    margin-top: .1em !important; }
  input {
    border: none;
    margin-bottom: .1em !important;
    margin-top: .1em !important; }
  .tag {
    &.is-active {
      background-color: $primary;
      color: findColorInvert($primary); } } }
