$divider-background-color: $grey-lighter !default;
$divider-font-size: $size-7 !default;
$divider-color: $grey-light !default;

.is-divider, .is-divider-vertical {
  display: block;
  position: relative; }

.is-divider[data-content]::after, .is-divider-vertical[data-content]::after {
  background: $white;
  color: $divider-color;
  content: attr(data-content);
  display: inline-block;
  font-size: $divider-font-size;
  padding: .5rem .8rem;
  -webkit-transform: translateY(-1.1rem);
  -ms-transform: translateY(-1.1rem);
  transform: translateY(-1.1rem);
  text-align: center; }

.is-divider {
  border-top: .1rem solid $divider-background-color;
  height: .1rem;
  margin: 2rem 0;
  text-align: center; }

.is-divider-vertical {
  display: block;
  padding: 2rem;
  position: relative;
  &::before {
    border-left: .1rem solid $divider-background-color;
    bottom: 1rem;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 1rem;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  &[data-content]::after {
    left: 50%;
    padding: .4rem 0;
    position: relative;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); } }
